import React, { useRef, useEffect, useMemo } from "react";
import * as Three from "three";
import { OrbitControls } from "three-orbitcontrols-ts";

interface ICard {
  width: number;
  height: number;
  rotationX?: number;
  rotationY?: number;
  positionZ?: number;
  frontImage: string;
  backImage: string;
}

const Card = (props: ICard) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scene = useMemo(() => new Three.Scene(), []);
  const textureLoader = useMemo(() => new Three.TextureLoader(), []);
  const renderer = useMemo(
    () =>
      new Three.WebGLRenderer({
        antialias: true,
        alpha: true,
      }),
    []
  );

  const camera = useMemo(
    () =>
      new Three.PerspectiveCamera(55, props.width / props.height, 0.5, 2000),
    [props.height, props.width]
  );

  useEffect(() => {
    camera.position.z = props.positionZ || 0;
    renderer.setSize(props.width, props.height);

    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }

    // Add ambient light to the scene
    const ambientLight = new Three.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Add a directional light to cast shadows
    const directionalLight = new Three.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);

    // Geometry
    const cardGeometry = new Three.BoxGeometry(8.6, 5.4, 0.04);

    // Card Front
    const cardFront = new Three.MeshStandardMaterial({
      metalness: 0.5,
      roughness: 0.4,
      map: textureLoader.load(props.frontImage),
    });
    cardFront.side = Three.FrontSide;

    // Card Back
    const cardBack = new Three.MeshStandardMaterial({
      metalness: 0.1,
      roughness: 0.2,
      map: textureLoader.load(props.backImage),
    });
    cardBack.side = Three.FrontSide;

    const cardBorder = new Three.MeshStandardMaterial({
      color: 0x4dff76,
      metalness: 0.1,
    });

    // Create the card
    const creditCard = new Three.Mesh(cardGeometry, [
      cardBorder,
      cardBorder,
      cardBorder,
      cardBorder,
      cardFront,
      cardBack,
      cardBorder,
      cardBorder,
    ]);

    // Add to the scene
    scene.add(creditCard);
    creditCard.rotation.y = props.rotationY || 0;
    creditCard.rotation.x = props.rotationX || 0;

    // Create interactive controls for rotating the card
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", render);

    // Create an animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();

    function render() {
      renderer.render(scene, camera);
    }
  }, [
    props.backImage,
    props.frontImage,
    props.height,
    props.positionZ,
    props.rotationX,
    props.rotationY,
    props.width,
    camera,
    scene,
    textureLoader,
    renderer,
  ]);

  return <div ref={containerRef} />;
};

export default Card;