import FrontCardImage from "./components/Card/front.jpg";
import BackCardImage from "./components/Card/back.jpg";
import Card from "./components/Card";

const App = () => {
  return (
    <div className="h-screen"
      style={{
        backgroundColor: "#ffeba4",
      }}
    >
      <div className="grid grid-cols-12 gap-4 pt-16 lg:pt-32 pr-5 pl-5 pb-10">
        <div className="block lg:hidden col-span-12 lg:col-span-6">
          <Card
            width={400}
            height={400}
            rotationX={-0.2}
            rotationY={0.3}
            positionZ={10}
            frontImage={FrontCardImage}
            backImage={BackCardImage}
          />
        </div>
        <div className="hidden lg:block col-span-12 lg:col-span-6">
          <Card
            width={600}
            height={300}
            rotationX={-0.2}
            rotationY={0.3}
            positionZ={7}
            frontImage={FrontCardImage}
            backImage={BackCardImage}
          />
        </div>
        <div className="mt-0 lg:mt-20 col-span-12 md:col-span-6">
          <h1 className="font-bold text-2xl lg:text-4xl">TamKart VISA Classic Debet</h1>
          <div className="flex align-items-center py-5">
            <p className="pr-5 truncate">
              <span className="mr-1">5</span>
              illik kart xidmətinin qiyməti
            </p>
            <p className="border-l-2 truncate pl-3 border-gray-900 font-semibold text-xl">
              0 ₼
            </p>
          </div>

          <div className="pt-2">
            <button className="border-2 hover:shadow-lg font-semibold rounded-lg px-3 py-2 border-gray-900">
              Sifariş et
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;